import React, {useEffect, useRef, useState} from 'react';
import {
  DeleteOutlined,
  PlusOutlined,
  MinusOutlined,
  PlusSquareFilled,
} from '@ant-design/icons';
import {Button, Collapse, DatePicker, notification, Select, Upload} from 'antd';
import moment from 'moment';
import {Controller, useFieldArray, useForm, useWatch} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {
  deleteFileFromGoal,
  deleteGoalApi,
  getPft,
  getPftGoals,
  getPftGoalsDataSource,
  patchPlan,
  patchSaveGoal,
  postPFT,
  saveGoals,
  saveSingleGoal,
  uploadFile,
  deleteFile,
} from '../../../../../../Services/Pft.service';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {getNotification} from 'Components/GetNotification';
import {ActionPlanPDF} from '../../../../../../Components/molecules/pdf/ActionPlanPdf';
import {generatePDFBlob} from '../../../../../../Components/molecules/pdf/ActionPlanPdf';
import './styles/Pftsteps.scss';
import {set} from 'lodash';
import {Link} from 'react-router-dom';
const {Panel} = Collapse;

const MESSAGES = {
  LABEL_AREAS_WORK_ON: 'Strategic Goal(s)',
  LABEL_PAT_RESULTS: 'Is there alignment between current priorities and goals?',
  LABEL_PRACTICE_VISION: 'What is the practice’s vision for transformation?',
  LABEL_PRIORITIES_PRACTICE: 'What are the current priorities of the practice?',
  LABEL_ADD_METRIC: 'Add a Metric',
};

const STYLE = {
  input:
    'bg-white rounded px-3 border border-solid !border-gray-400 font-medium grow !min-w-0',
  textarea:
    'px-3 py-1 rounded border border-solid !border-gray-400 min-h-[144px] text-lg font-medium placeholder:text-gray-700',
  button: `px-6 py-3 rounded bg-primary text-white font-medium text-base transition-colors duration-150 
    hover:bg-primary-hover 
    disabled:bg-disabled disabled:text-disabled-text disabled:cursor-not-allowed`,
  buttonSecondary: `px-2 py-1 rounded font-medium border border-solid border-primary`,
};

const HeaderActions = ({onCancel, onSave, data}) => {
  return (
    <div className='flex gap-3 justify-end'>
      {/* <button 
      type='button' 
      className={STYLE.button} 
      onClick={onCancel}>
      Cancel
    </button> */}
      <button type='submit' className={STYLE.button} onClick={onSave}>
        Save Changes
      </button>
      {/* <button type='button' className={STYLE.button} onClick={onExportPDF}>
        Export PDF
      </button> */}
      <PDFDownloadLink
        document={<ActionPlanPDF data={data} />}
        fileName={`test.pdf`}>
        <button type='button' className={STYLE.button}>
          Export PDF
        </button>
      </PDFDownloadLink>
    </div>
  );
};

const MetricHeader = () => (
  <div className='flex gap-3 bg-[#eaeaea] p-2 text-base font-medium'>
    <span className='grow-[6]'>Metric</span>
    <span className='grow'>Target State</span>
    <span className='grow'>% of #</span>
    <span className='grow'>Current State</span>
    <span className='grow'>Trend</span>
    <span className='w-[60px]' />
  </div>
);

/**
 * Renders the Pftsteps component.
 *
 * @return {JSX.Element} The rendered component.
 */
const Pftsteps = ({onCancel, pftId, pftPlanTitle, setPlanTitle}) => {
  const [goalId, setGoalId] = useState(0);
  const [fileListState, setFileListState] = useState([]);
  const [linkInput, setLinkInput] = useState({});
  const [linkListState, setLinkListState] = useState([]);
  const [planStatus, setPlanStatus] = useState('PFT_COMPLETED');
  const fileListLinks = useRef([]);
  const [linkId, setLinkId] = useState(0);
  const [planTitle, setplanTitle] = useState(pftPlanTitle)

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: {errors},
    watch,
    getValues,
  } = useForm();

  // Initialize a state variable to store the form data
  const [formData, setFormData] = useState({
    author: 'Kevin Sanchez',
    updatedOn: '07/20/23',
    title: 'MA Action Plan #1',
    vision: watch('practice_vision'),
    priorities: watch('practice_priorities'),
    alignment: watch('practice_alignment'),
    startegicGoals: watch('strategic_goals'),
    goals: watch('goals'),
  });

  // // Watch the form data for changes

  const watchVision = watch('practice_vision');
  const watchPriorities = watch('practice_priorities');
  const watchAlignment = watch('practice_alignment');
  const watchStrategicGoals = watch('strategic_goals');
  const watchGoals = watch('goals');

  // Update the state variable whenever the form data changes
  useEffect(() => {
    setFormData({
      ...formData,
      vision: watchVision,
      priorities: watchPriorities,
      alignment: watchAlignment,
      strategicGoals: watchStrategicGoals,
      goals: watchGoals,
    });
  }, [
    watchVision,
    watchPriorities,
    watchAlignment,
    watchStrategicGoals,
    watchGoals,
  ]);

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'goals',
  });

  const watchFieldArray = watch('goals');

  let controlledFields = [];

  if (watchFieldArray) {
    controlledFields = fields.map((field, index) => {
      return {
        ...field,
        ...watchFieldArray[index],
      };
    });
  }

  const network = useSelector((state) => {
    return state.network.value;
  });

  const client_type = [
    {
      label: 'For Population Health Customers',
      value: 'P_HEALTH_CUSTOMERS',
    },
    {
      label: 'For Quality Improvement Customers (won’t take PAT)',
      value: 'Q_IMPROVEMENT_CUSTOMERS',
    },
  ];

  const current_state_perf_option = [
    {
      label: 'Below Target',
      value: 'BELLOW_TARGET',
    },
    {
      label: 'Above Target',
      value: 'ABOVE_TARGET',
    },
    {
      label: 'Close Target',
      value: 'CLOSE_TARGET',
    },
  ];

  /**
   * Deletes a file from the given data source and updates the file list state.
   */

  const handleDeleteFile = (id) => {
    deleteFile(id)
      .then((response) => {
        loadDataByPft();
      })
      .catch((error) => {
        console.error(` file delete failed: ${error.message}`);
      });
  };

  /**
   * Deletes a goal.
   *
   * @param {string} key - The key of the goal.
   * @param {string} goalId - The ID of the goal to be deleted.
   * @param {number} index - The index of the goal in the list.
   * @return {undefined} There is no return value.
   */
  const deleteGoal = async (key, goalId, index) => {
    try {
      await deleteGoalApi(goalId).call;
      delete fileListState[goalId];
      remove(index);
    } catch (error) {}
    notification.close(key);
  };

  /**
   * Confirms the deletion of a document related to a goal.
   *
   * @param {Object} e - the event object
   * @param {number} index - the index of the document
   * @return {boolean} - whether the deletion is confirmed or not
   */
  const confirmDelete = (e, index) => {
    const key = `open${Date.now()}`;
    let toConfirm = false;
    const btn = (
      <Button
        type='primary'
        size='small'
        onClick={() => deleteFile(e, key, index)}>
        Confirm
      </Button>
    );
    notification.open({
      message: 'Attention',
      description:
        'You are about to delete a document related to a goal, are you sure to do it?.',
      btn,
      key,
    });

    return toConfirm;
  };

  /**
   * Confirm deleting a goal.
   *
   * @param {string} goalId - The ID of the goal to delete.
   * @param {number} index - The index of the goal in the list.
   * @return {void} No return value.
   */
  const confirmDeleteGoal = (goalId, index) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type='primary'
        size='small'
        onClick={() => deleteGoal(key, goalId, index)}>
        Confirm
      </Button>
    );
    notification.open({
      message: 'Attention',
      description: 'You are about to delete a goal, are you sure to do it?',
      btn,
      key,
    });
  };

  // HandeFileUpload
  const handleFileUpload = async (file, id) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('goal', id);

    try {
      const response = await uploadFile(formData);

      if (response.status === 201) {
        loadDataByPft();
      }
    } catch (error) {
      console.error(`${file.name} file upload failed: ${error.message}`);
    }
  };

  // /**
  //  * Generate the upload props for a given index.
  //  *
  //  * @param {number} index - The index of the file list state.
  //  * @return {object} Returns an object with upload properties.
  //  */
  const uploadProps = (index) => {
    if (!fileListState.hasOwnProperty(index)) {
      setFileListState({
        ...fileListState,
        [index]: {files: [], links: [], goalId: goalId},
      });
      setGoalId((prev) => prev + 1);
    }

    return {
      action: () => {},
      onRemove: (e) => {
        if (e.hasOwnProperty('data_source')) {
          confirmDelete(e, index);
          return false;
        } else {
          return true;
        }
      },
      beforeUpload: () => {
        return false;
      },
      onChange({file, fileList}) {
        setFileListState({
          ...fileListState,
          [index]: {...fileListState[index], files: fileList},
        });
      },
      fileList: fileListState.hasOwnProperty(index)
        ? fileListState[index]?.files?.map((item) => ({
            ...item,
            uid: item?.id || item?.uid,
            name: item?.filename || item?.name,
            url: item?.file,
            status: 'done',
          }))
        : [],
    };
  };

  /**
   * Handles the 'Ok' action.
   *
   * @param {Object} params - The parameters for the function.
   * @return {Promise} The result of the function execution.
   */
  const handleOk = async (params) => {
    // Create a dataPlan object by spreading the form parameters
    // and adding additional properties like status, organization, and formatted 'created' date
    const dataPlan = {
      ...params,
      status: planStatus, // Add the plan status
      organization: network, // Add the organization
      created: moment(params.created), // Format the 'created' date using moment.js
    };

    // Remove the 'goals' property from dataPlan
    // delete dataPlan.goals;

    // Create a dataGoals object that maps over the 'goals' array in params
    // and formats each goal item, including adding data sources and formatting dates
    const dataGoals = {
      goals: params.goals.map((item) => {
        // Find the associated file based on 'goalId' in fileListState
        const file = Object.keys(fileListState).find(
          (key) => fileListState[key].goalId == item.goalId
        );

        let links;

        if (item.hasOwnProperty('id')) {
          // Find the associated link based on 'goalId' in linkListState
          links = Object.keys(linkListState).find(
            (key) => linkListState[key].goalId == item.id
          );
        } else {
          // Find the associated link based on 'goalId' in linkListState
          links = Object.keys(linkListState).find(
            (key) => linkListState[key].goalId == item.goalId
          );
        }

        // Add 'dataSources' to the goal item
        item.dataSources = fileListState[file];

        // Add 'links' to the goal item
        item.links = linkListState[links];

        // Format 'data_pulled_at' date using moment.js
        item.data_pulled_at = moment(item.data_pulled_at);

        // Delete 'goalId' from the goal item
        delete item.goalId;

        return item;
      }),
    };

    try {
      // Check if dataPlan has an 'id' property to determine whether to update or create
      if (dataPlan.hasOwnProperty('id')) {
        // If 'id' exists, it's an update operation, so patch the plan
        const resPlan = await patchPlan(dataPlan);

        // Iterate over the goals in dataGoals and update or save each goal
        const resGoalsPatch = await Promise.all(
          dataGoals.goals.map(async (item) => {
            if (item.hasOwnProperty('id')) {
              await patchSaveGoal(item); // Update an existing goal

              if (
                fileListState[item.id] &&
                fileListState[item.id].files.length > 0
              ) {
                await Promise.all(
                  fileListState[item.id].files.map(async (file) => {
                    // Assuming 'fileObject' is the object with 'data', 'filename', and 'type' properties
                    await handleFileUpload(file.originFileObj, item.id);
                  })
                );
              }
            } else {
              await saveSingleGoal(resPlan.data.id, item); // Save a new goal
            }
          })
        );

        // Wait for all goal update/save operations to complete
        await Promise.all(resGoalsPatch);
      } else {
        // If 'id' doesn't exist, it's a create operation, so post a new plan
        const resPlan = await postPFT({
          organization: dataPlan.organization,
          title: dataPlan.title,
          practice_vision: dataPlan.practice_vision,
          practice_priorities: dataPlan.practice_priorities,
          practice_alignment: dataPlan.practice_alignment,
          strategic_goals: dataPlan.strategic_goals,
        }).call;

        // Save the goals associated with the newly created plan
        await saveGoals(resPlan.data.id, dataGoals);
      }

      // Call the onCancel function when the operation is successful
      onCancel();
      setPlanTitle('');
    } catch (error) {
      // Handle any errors that occur during the operation
      // (You may want to add error handling logic here)
      getNotification('error', {
        header: 'Error',
        body: 'Something went wrong when saving the changes.',
      });
    }
  };

  /**
   * Load data by Pft.
   *
   * @return {Promise<void>} - Returns a promise that resolves when the data is loaded.
   */
  const loadDataByPft = async () => {
    try {
      const resPft = await getPft(pftId).call;

      Object.keys(resPft.data).forEach((key) => {
        setValue(key, resPft.data[key]);
      });

      const resGoals = await getPftGoals(pftId).call;

      // Initialize new fileListState, and linkListState objects
      let newFileListState = {};
      let newLinkListState = {};

      resGoals.data.results.forEach((goal) => {
        if (goal.files.length > 0) {
          // Create the links array for this goal
          const links = goal.files.map((file) => ({
            id: file.id,
            link: file.file,
            title: file.filename,
          }));
          // Update the fileListState with the links for this goal
          newFileListState[goal.id] = {links};
        }

        // Make sure there is always a linkListState object for each goal
        let linkArr = [];

        // If there is a link string, split it and make it an arrar.
        if (goal.links.length > 0) {
          linkArr = goal.links.split(',');
        }
        // Update the linkListState with the links for this goal
        newLinkListState[goal.id] = {
          links: linkArr,
          goalId: goal.id,
        };
      });

      // Now, set the fileListState to the newFileListState
      setFileListState(newFileListState);

      // Now, set the linkListState to the newLinkListState
      setLinkListState(newLinkListState);

      setValue('goals', resGoals.data.results);
    } catch (error) {
      // Handle errors here
    }
  };

  useEffect(() => {
    setFileListState(fileListState);
  }, [fileListState]);

  useEffect(() => {
    setLinkListState(linkListState);
  }, [linkListState]);

  useEffect(() => {
    if (pftId) {
      loadDataByPft();
    }
  }, [pftId]);

  // handles input for link
  const handleLinkInputChange = (event, goalId) => {
    const newValue = event.target.value;
    setLinkInput((prevLinkInput) => ({
      ...prevLinkInput,
      [goalId]: newValue,
    }));
  };

  // Called when a user clicks the plus button on the link input
  // takes an id, labeled as index, as a parameter.
  const handleLinkButtonClick = (index) => {
    // Access the input value for the specific goalId
    const inputValue = linkInput[index];
    if (!linkListState.hasOwnProperty(index)) {
      setLinkListState({
        ...linkListState,
        [index]: {links: [], goalId: goalId - 1},
      });
    }
    // After ensuring the goal exists, add the inputValue to the links array of that goal
    if (inputValue.trim() !== '') {
      setLinkListState((prevLinkListState) => ({
        ...prevLinkListState,
        [index]: {
          ...prevLinkListState[index],
          links: [...prevLinkListState[index].links, inputValue],
        },
      }));
      setLinkInput(''); // Clear the input
    }
  };

  // Called when a user clicks the trash icon next to a link.
  // Currently does not hit api unless save button is clicked
  const removeLink = (goalId, index) => {
    setLinkListState((prevLinkListState) => {
      // Check if the goalId exists in linkListState
      if (prevLinkListState.hasOwnProperty(goalId)) {
        const updatedLinks = [...prevLinkListState[goalId].links];

        // Check if the index is within bounds
        if (index >= 0 && index < updatedLinks.length) {
          // Use splice to remove the element at the specified index
          updatedLinks.splice(index, 1);

          // Create a new state object with the updated links array
          const updatedState = {
            ...prevLinkListState,
            [goalId]: {
              ...prevLinkListState[goalId],
              links: updatedLinks,
            },
          };
          return updatedState;
        }
      }
      return prevLinkListState; // Return the original state if the removal wasn't successful
    });
  };

  return (
    <div className='pftsteps'>
      <form onSubmit={handleSubmit(handleOk)} className='grid gap-4'>
        <div className='flex space-x-4 gap-3'>
          <div>
            <p>Date Created</p>
            <Controller
              name='created'
              x
              control={control}
              render={({field}) => (
                <DatePicker
                  {...field}
                  placeholderText='Select date'
                  onChange={(date) => field.onChange(date)}
                  value={field.value ? moment(field.value) : ''}
                  format='MM-DD-YYYY'
                />
              )}
            />
          </div>
          <div>
            <p>Title</p>
            <div>
              <Controller
                name='title'
                x
                control={control}
                render={({field}) => (
                  <input
                    className={`${STYLE.input} py-[0.465rem]`}
                    type='text'
                    {...register('title')}
                    placeholder={field.value ? field.value : ''}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className='flex gap-3 justify-end'>
          <button
            type='submit'
            className={STYLE.button}
            onClick={() => setPlanStatus('PFT_COMPLETED')}>
            Save Changes
          </button>

          <PDFDownloadLink
            document={<ActionPlanPDF data={formData} />}
            fileName={`test.pdf`}>
            <button type='button' className={STYLE.button}>
              Export PDF
            </button>
          </PDFDownloadLink>
        </div>
        <Collapse
          defaultActiveKey={['1', '2']}
          expandIconPosition='end'
          ghost={true}>
          <Panel header='Practice Vision' key='1'>
            <section className='grid gap-3'>
              <label
                className='text-black text-lg font-semibold'
                htmlFor='ma_action_plan_practice_vision'>
                {MESSAGES.LABEL_PRACTICE_VISION}
              </label>
              <textarea
                className={STYLE.textarea}
                id='ma_action_plan_practice_vision'
                {...register('practice_vision')}></textarea>

              <label
                className='text-black text-lg font-semibold'
                htmlFor='ma_action_plan_practice_priorities'>
                {MESSAGES.LABEL_PRIORITIES_PRACTICE}
              </label>
              <textarea
                className={STYLE.textarea}
                id='ma_action_plan_practice_priorities'
                {...register('practice_priorities')}></textarea>

              <label
                className='text-black text-lg font-semibold'
                htmlFor='ma_action_plan_practice_alignment'>
                {MESSAGES.LABEL_PAT_RESULTS}
              </label>
              <textarea
                className={STYLE.textarea}
                id='ma_action_plan_practice_alignment'
                {...register('practice_alignment')}></textarea>
            </section>
          </Panel>
          <Panel header='Action Plan' key='2'>
            <section className='grid gap-3'>
              <label
                className='text-black text-lg font-semibold'
                htmlFor='ma_action_plan_strategic_goals'>
                {MESSAGES.LABEL_AREAS_WORK_ON}
              </label>
              <textarea
                className={STYLE.textarea}
                id='ma_action_plan_strategic_goals'
                {...register('strategic_goals')}></textarea>
            </section>
          </Panel>
        </Collapse>
        <div className='flex justify-end'>
          {/* <div>
            <p>Client Type</p>
            <Controller
              name='client_type'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  defaultValue={getValues('client_type')}
                  className='select-class'
                  placeholder='Choose an option'
                  optionFilterProp='children'
                  onChange={(net) => {
                    field.onChange(net);
                  }}
                  bordered={false}
                  options={client_type}
                />
              )}
            />
          </div> */}
          <button
            type='button'
            className={STYLE.buttonSecondary}
            onClick={() => {
              append({
                title: '',
                trend: '',
                data_pulled_at: '',
                goalId: goalId,
              });
            }}>
            {MESSAGES.LABEL_ADD_METRIC}
          </button>
        </div>
        <MetricHeader />
        <section className='divide-y divide-solid divide-primary'>
          {controlledFields?.map((goal, index) => (
            <div className='grid gap-4 py-4' key={index}>
              <div className='flex flex-wrap gap-3'>
                <div className="flex flex-col grow-[3]">
                  <input
                    className={`${STYLE.input} grow-[3]`}
                    type='text'
                    {...register(`goals.${index}.title`, {
                      required: "Metric is required",
                      minLength: {
                        value: 3,
                        message: "Metric must be at least 3 characters"
                      }
                    })}
                    placeholder='Metric'
                  />
                  {errors.goals && errors.goals[index]?.title && (
                    <span className="text-red-500 text-xs">
                      {errors.goals[index].title.message}
                    </span>
                  )}
                </div>

                <div className="flex flex-col">
                  <input
                    className={STYLE.input}
                    type='number'
                    {...register(`goals.${index}.target`, {
                      required: "Target State is required",
                      min: {
                        value: 0,
                        message: "Target State must be at least 0"
                      },
                      validate: { isNumber: value => !isNaN(value) || "Target State must be a number" }
                    })}
                    placeholder='Target State'
                  />
                  {errors.goals && errors.goals[index]?.target && (
                    <span className="text-red-500 text-xs">
                      {errors.goals[index].target.message}
                    </span>
                  )}
                </div>

                <div className="flex flex-col">
                  <Controller
                    name={`goals.${index}.goal_type`}
                    control={control}
                    rules={{ required: "Goal type is required" }}
                    render={({ field, fieldState }) => (
                      <Select
                        {...field}
                        className={`${STYLE.input} min-w-[60px]`}
                        optionFilterProp='children'
                        bordered={false}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        options={[
                          { value: 'PERCENTAGE', label: '%' },
                          { value: 'NUMBER', label: '#' },
                        ]}
                      />
                    )}
                  />
                  {errors.goals && errors.goals[index]?.goal_type && (
                    <span className="text-red-500 text-xs">
                      {errors.goals[index].goal_type.message}
                    </span>
                  )}
                </div>

                <div className="flex flex-col">

                  <input
                    className={STYLE.input}
                    type='number'
                    {...register(`goals.${index}.current_state`, {
                      required: "Current State is required",
                      min: {
                        value: 0,
                        message: "Current State must be at least 0"
                      },
                      validate: { isNumber: value => !isNaN(value) || "Target State must be a number" }
                    })}
                    placeholder='Current State'
                  />
                  {errors.goals && errors.goals[index]?.target && (
                    <span className="text-red-500 text-xs">
                      {errors.goals[index].target.message}
                    </span>
                  )}
                </div>


                <div className="flex flex-col">
                  <Controller
                    name={`goals.${index}.trend`}
                    control={control}
                    rules={{ required: "Trend is required" }}
                    render={({ field, fieldState }) => (
                      <Select
                        {...field}
                        className={`${STYLE.input} !min-w-[145px]`}
                        placeholder='Choose an option'
                        optionFilterProp='children'
                        bordered={false}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        options={current_state_perf_option}
                      />
                    )}
                  />
                  {errors.goals && errors.goals[index]?.trend && (
                    <span className="text-red-500 text-xs">
                      {errors.goals[index].trend.message}
                    </span>
                  )}
                  </div>
                  <div className='flex items-center shrink-0 gap-3'>
                  <button
                    type='button'
                    className='w-6 h-6 rounded flex items-center justify-center bg-primary hover:bg-primary-hover'
                    onClick={() => {
                      append({
                        title: '',
                        trend: '',
                        data_pulled_at: '',
                        goalId: goalId,
                      });
                    }}>
                    <PlusOutlined style={{color: 'white'}} />
                  </button>
                  <button
                    type='button'
                    className='w-6 h-6 rounded flex items-center justify-center bg-primary hover:bg-primary-hover'
                    onClick={() => {
                      if (goal.hasOwnProperty('pft')) {
                        confirmDeleteGoal(goal.id, index);
                      } else {
                        delete fileListState[goal.id];
                        remove(index);
                      }
                    }}>
                    <MinusOutlined style={{color: 'white'}} />
                  </button>
                </div>
                </div>
                <div className='flex gap-3'>
                <div className='grid flex-1'>
                  <Upload
                    className='flex-1 text-base'
                    style={{width: '100%'}}
                    {...uploadProps(goal.id)}>
                    <button type='button' className={`${STYLE.input} flex-1`}>
                      Upload documents (Optionally)
                    </button>
                  </Upload>
                </div>
                <div className='grid flex-1 gap-2.5 max-h-10'>
                  <div className={`${STYLE.input} flex gap-2`}>
                    <input
                      className={'py-2 flex-1'}
                      type='text'
                      placeholder='Enter link'
                      id='link-input'
                      value={linkInput[goal.id] || ''}
                      onChange={(e) => handleLinkInputChange(e, goal.id)}
                    />
                    <button
                      type='button'
                      className='w-6 h-full flex items-center justify-center'
                      onClick={() => handleLinkButtonClick(goal.id)}>
                      <PlusOutlined style={{color: '#676869'}} />
                    </button>
                  </div>
                </div>
              </div>
              <div className='grid grid-cols-2 gap-x-16'>
                <div>
                  {fileListState[goal.id]?.links?.map((item, i) => (
                    <div key={i} className='flex justify-between mt-2'>
                      <a
                        className='text-base font-medium'
                        href={item.link}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {item.title}
                      </a>
                      <button
                        onClick={() => {
                          handleDeleteFile(item.id);
                        }}>
                        <DeleteOutlined
                          style={{color: '#F46767', fontSize: 20}}
                        />
                      </button>
                    </div>
                  ))}
                </div>
                <div>
                  {linkListState[goal.id]?.links?.map((item, i) => (
                    <div key={i} className='flex justify-between mt-2'>
                      <a
                        className='text-base font-medium'
                        href={item}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {item}
                      </a>
                      <button
                        type='button'
                        onClick={() => removeLink(goal.id, i)}>
                        <DeleteOutlined
                          style={{color: '#F46767', fontSize: 20}}
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div> 
          ))}
        </section>
      </form>
    </div>
  );
};

export default Pftsteps;
